.wrap-session-gallery {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .session-gallery {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    text-align: center;
    margin: 0;
    padding: 0;
    background-color: #5b5b5bf3;

    .slick-slider {
      max-height: 100vh;
      overflow: hidden;

      .gallery-item {
        width: 100vw;
        height: 100vh;
        position: relative;
        display: flex !important;
        justify-content: center;
        align-items: center;

        .gallery-hotspot {
          position: absolute;
          z-index: 100;
          cursor: pointer;
          width: 30px;
        }

        img {
          width: 80vw;
          height: 95vh;
          object-fit: contain;
        }

        .gallery-content {
          width: 420px;
          padding: 35px;
          display: flex;
          flex-direction: column;
          background-color: #FFFFFF;
          position: absolute;

          span {
            color: #1A1A1A;
            /* Popup headers */
            font-family: 'Roos St Regis Text';
            font-size: 28px;
            font-style: italic;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.14px;
            text-align: left;
          }

          span:last-child {
            color: #767676;
            font-size: 16px;
            font-style: normal;
            line-height: 150%;
            margin-top: 20px;
          }
        }

        .left-15 {
          left: 15vw;
        }

        .right-15 {
          right: 15vw;
        }
      }
    }

    .slick-dots {
      display: none !important;
      bottom: 70px;

      li {
        margin: 0 2px;

        button:before {
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 6px;
          border: 1px solid #fff;
          background-color: transparent;
          opacity: 1;
        }

        &.slick-active {
          button:before {
            background-color: #fff;
          }
        }
      }
    }

    .btn-gallery {
      display: block;
      position: absolute;
      top: 50%;
      cursor: pointer;
    }

    .btn-gallery.prev-slide {
      left: 25px;
      color: rgba(255, 255, 255, 0.6);

      img {
        transform: rotate(180deg);
        width: 50px;
      }
    }

    .btn-gallery.next-slide {
      right: 25px;
      color: rgba(255, 255, 255, 0.6);

      img {
        width: 50px;
      }
    }

    .img-close {
      position: absolute;
      top: 35px;
      right: 35px;
      width: 29px;
      height: 29px;
      cursor: pointer;
    }
  }

}

.wrap-session-floorplan {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .wrap-floor-plan-gallery {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 60;
    top: 0;
    left: 0;
    text-align: center;
    margin: 0;
    padding: 0;
    background-color: rgb(246, 246, 246);

    .slick-slider {
      max-height: 100vh;
      overflow: hidden;

      .slick-slide {
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
      }
  
      .floorplan-item {
        width: 100vw;
      }
    }
  }

  .wrap-close-btn {
    position: absolute;
    bottom: 110px;
    z-index: 1000;
    width: 100%;
    padding: 0 36px;
    display: flex;
    justify-content: space-between;

    div {
      cursor: pointer;

      span {
        color: #1A1A1A;
        font-family: "Suisse Int'l";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.048px;
        text-transform: uppercase;
      }

      img {
        margin-right: 10px;
      }

      .rotate {
        transform: rotate(180deg);
      }

      .txt-white {
        color: #ffffff;
      }
    }
  }

}
