//font

/* Graphie */
@import url('https://use.typekit.net/hom6irb.css');

/* Josefin Sans */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500&display=swap');
/* Philosopher */
@import url('https://use.typekit.net/xcu1jzs.css');

@font-face {
  font-family: 'Futura Pt';
  src: url('./fonts/FuturaPTLight.otf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Pt Book';
  src: url('./fonts/FuturaPTBook.otf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Pt Light';
  src: url('./fonts/FuturaPTLight.otf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Pt Bold';
  src: url('./fonts/FuturaPTBold.otf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roos St Regis Text';
  src: url('./fonts/RoosStRegisText-Regular.woff2.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./fonts/SuisseIntl-Regular.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Suisse Int';
  src: url('./fonts/SuisseIntl-Regular.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Int Bold';
  src: url('./fonts/SuisseIntl-Bold.otf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roos St Regis Text';
  src: url('./fonts/RoosStRegisText-Regular.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roos St Regis Text 2';
  src: url('./fonts/RoosStRegisText-Regular.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
  unicode-range: U+0000-00FF, U+0100-017F; /* Include basic Latin and Latin-1 Supplement */ 
}

@import './common';

@import "~bootstrap/scss/bootstrap";
@import "~react-toastify/dist/ReactToastify.css";

$utilities: map-get-multiple($utilities,
  ('float',
    'text-transform',
    'display',
    'justify-content',
    'flex-direction',
    'text-align',
    'position',
    'height',
    'padding-y'
  ));
@import '../node_modules/bootstrap/scss/utilities/api';

// Pretty checkbox
@import '~pretty-checkbox/src/pretty-checkbox.scss';
@import "~@fancyapps/ui/dist/fancybox.css";

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}

label {
font-family: 'SuisseIntl';
color: #898989;
font-size: 14px;
}

.list-group {
  &-item {
    opacity: $list-group-item-color-scale;
    cursor: pointer;

    &.active {
      font-weight: 500;
      opacity: 1;
    }

    &:hover {
      //color: pointer;
    }

    font-weight: 100;
    font-size: 18px;
  }

  &-heading {
    text-transform: uppercase;
    font-weight: 400;
    opacity: 0.875;
  }
}

.d-flex-column {
  @extend .d-flex;
  @extend .flex-column;

  &>* {
    flex-shrink: 0;
  }
}

.title,
.f-title,
.card-title {
  font-weight: bold;
  text-transform: uppercase;
}

.btn-text {
  color: #000000;
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: #000000;
  }
}

.side-card {
  height: calc(100vh - 200px);
  border: none;
  border-radius: 0;
  position: absolute;
  z-index: 100;
  top: 100px;
  width: 300px;
  padding: 20px;
  background: rgba(30, 30, 30, 0.9);

  h2 {
    position: relative;
  }

  h2::before {
    content: '';
    position: absolute;
    left: -1.25rem;
    margin-top: -2px;
    top: 50%;
    width: 25px;
    height: 2px;
    background-color: #ffffff;

    @media screen and (max-width: 1025px) {
      width: 15px;
    }
  }

  .card {
    box-shadow: none;
    border: 0px;
    color: #ffffff;
    background: transparent;
  }

  .card-title {
    padding: 0.5rem 1rem;
    color: #fff;
    font-weight: 300;
    font-size: 1.7rem;

    @media screen and (max-width: 1025px) {
      padding: 0.5rem 0.5rem;
    }
  }

  .btn-arcodion {
    cursor: pointer;
  }

  .wrap-btn-detail .list-group .list-group-item,
  .card-header,
  .list-group-item {
    border: none;
    padding: 0.5rem 1rem;
    color: #000000;
    background-color: transparent;
    font-size: 14px;

    @media screen and (max-width: 1025px) {
      padding: 0.5rem 0.5rem;
    }

    &.active {
      font-weight: 400;
      opacity: 1;
    }

    &:hover {
      font-weight: 400;
      opacity: 1;
    }
  }

  .list-group-heading {
    opacity: 1;
  }

  .card-body {
    padding: 0;
    height: calc(100% - 52px);
    overflow-y: auto;
    background-color: transparent;

    .acordian {
      background: rgba(30, 30, 30, 0.9);
    }
  }

  &.left-card {
    left: 0;
    top: 90px;
    background-color: #FFFFFF;
  }

  &.right-card {
    right: 0;
    top: 90px;
    background-color: #FFFFFF;
  }
}

.title-center-bottom {
  position: absolute;
  margin-bottom: 0;
  bottom: 24px;
  left: 50vw;
  transform: translateX(-50%);
  color: #ffffff;
  z-index: 111;
  font-size: 2rem;
}

.invisible {
  opacity: 0;
}

.b-fade-text {
  * {
    animation: fadeTop forwards;
    opacity: 0;

    &:nth-child(1) {
      animation-duration: 1s;
    }

    &:nth-child(2) {
      animation-duration: 1.2s;
    }

    &:nth-child(3) {
      animation-duration: 1.4s;
    }

    &:nth-child(4) {
      animation-duration: 1.6s;
    }

    &:nth-child(5) {
      animation-duration: 1.8s;
    }

    &:nth-child(6) {
      animation-duration: 2s;
    }
  }
}

@keyframes fadeTop {
  0% {
    opacity: 0;
    transform: translateY(70px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.underline {
  &-0 {
    text-decoration: none;
  }
  &-sm {
    display: inline-block;
    position: relative;
    &::before {
      content:'';
      display: block;
      width: 30%;
      height: 3px;
      background: #000;
      position: absolute;
      left: 0;
      bottom: -18px;
    }
  }
}

@each $name, $value in $colors {
  .bg-#{$name} {
    background: $value!important;
  }
}

.wrap-bottom-titles {
  bottom: 30px;
  color: $white;
  left: 33px;
  right: 33px;

  .btn-replay {
    line-height: 15px;
    &.underline-sm {
      &::before {
        width: 75px;
        background: $white;
        bottom: -9px;
        height: 1px;
      }
    }
  }
  .svg-icon {
    border-radius: 50%;
    width: fit-content;
    border: 2px solid #fff;
    width: 45px;
    height: 45px;
    display: inline-block;
    text-align: center;
    line-height: 36px;
    vertical-align: middle;
    margin-top: -45px;
    svg {
      max-width: 100%;
      max-height: 100%;
      cursor: pointer;
    }
  }
}

.transparent {
  opacity: 0;
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}
.opacity0{
  opacity: 0!important;
}

.btn-enter{
  display: inline-flex;
  padding: 20px 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #1A1A1A;
  color: #FFF;
  text-align: center;
  /* Form text */
  font-family: 'Suisse Int';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  border: solid 1px #1A1A1A;
  text-transform: uppercase;
}

@media (hover: hover) and (pointer: fine) {
  .btn-enter:hover {
    color: #1A1A1A;
    background: #FFF;
  }
}

.btn-enter-line{
  background: transparent;
  color: #1A1A1A;
  text-align: center;
  /* Form text */
  font-family: 'Suisse Int';
  font-size: 13px;
  letter-spacing: 0.052px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  border: none;
  border-bottom: solid 1px #1A1A1A;
  text-transform: uppercase;
  width: fit-content;
  padding-left: 0;
  padding-right: 0;
}

.btn-enter-white{
  display: inline-flex;
  padding: 20px 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #ffffff;
  color: #1A1A1A;
  text-align: center;
  /* Form text */
  font-family: 'Suisse Int';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.12);
  text-transform: uppercase;
  border: none;
  &:hover{
    color: #fff;
    background: #1A1A1A;
  }
}

.btn-skip-video {
  position: absolute;
  bottom: 180px;
  font-weight: 700;
  border-radius: 0;
  font-size: 15px;
  line-height: 20.25px;
  width: 100%;
  height: 51px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  display: flex;
  .skip-video{
    background: #FFF;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.12);
    border: 0;
    padding: 14px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1a1a1a;
    img{
      margin-left: 10px;
    }
  }
}

.for-chrome {
  @media screen and (max-width: 1367px) {
    bottom: 160px;
  }
}

.btn-center{
  position: absolute;
  bottom: 130px;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  display: flex;
  .replay-video{
    background: #FFF;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.12);
    border: 0;
    padding: 14px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-feature-settings: 'cpsp' on;
    font-family: 'Proxima Nova';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 16.25px */
    letter-spacing: 0.2px;
    text-transform: uppercase;
    img{
      margin-right: 10px;
      margin-left: 10px;
    }
    .rotate{
      transform: rotate(180deg);
    }
  }
}

.btn-disable{
  color: #1A1A1A;
  background: #f3f3f3;
  pointer-events: none;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.duration{
  &-300{
    transition-duration: 300ms;
  }
  &-500{
    transition-duration: 500ms;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.absolute {
  position: absolute !important;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.first-loading {
  display: inline-block;
  height: 100%;
  position: absolute;
  z-index: 30;
  top: 0;
  left: 0;
  overflow: hidden;
  margin-bottom: 3rem;
  background-color: #DDDBDD;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }
}


