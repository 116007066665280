.cms-container {
  margin-top: 80px;

  .cms-header {
    display: flex;
    height: 80px;
    padding: 23px 50px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;

    border-bottom: 1px solid #E6E7E9;
    background: #FFF;

    .cms-header-title {
      margin: 0;
      color: #1A1A1A;

      /* Headers */
      font-family: 'Roos St Regis Text';
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.14px;
    }

    .cms-header-content {
      display: flex;
      height: 24px;
      justify-content: flex-end;
      align-items: center;
      gap: 30px;

      .cms-header-content-left {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 50px;
      }

      .cms-header-content-div {
        width: 1px;
        height: 100%;
        background: #E6E7E9;
      }

      .cms-header-content-right {
        display: flex;
        align-items: flex-start;
        gap: 50px;
      }

      .cms-header-item {
        cursor: pointer;
        color: #1A1A1A;

        /* Header Categories */
        font-family: 'Suisse Int';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.048px;
        text-transform: uppercase;

        &.active {
          border-bottom: 0.5px solid #1A1A1A90;
          padding-bottom: 4px;
        }
      }

      a.cms-header-item {
        text-decoration: none;
      }

      .item-with-icon {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    @media screen and (max-width: 1025px) {
      height: fit-content;
      flex-direction: column;
      gap: 24px;
    }
  }

  .cms-content {
    padding: 93px 70px 93px 90px;
    background: #F9F9F9;
    height: calc(100vh - 160px);

    .cms-content-wrap {
      height: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      //align-items: flex-start;
      gap: 21px;
      padding-right: 20px;

			&::-webkit-scrollbar {
				width: 4px !important;
        background-color: #ececec;
			}
      &::-webkit-scrollbar-thumb {
        border-color: #a7a7a7;
      }
      &::-webkit-scrollbar-track {
        display: none;
			}

      .cms-content-faq {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 13px;

        .cms-content-faq-header {
          cursor: pointer;

          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;

          .cms-content-faq-text {
            color: #1A1A1A;

            /* CMS Header */
            font-family: 'Roos St Regis Text';
            font-size: 20px;
            font-style: italic;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.1px;
            pointer-events: none;
          }

          span.collapse {
            pointer-events: none;
          }
        }

        .cms-content-faq-body {
          color: #767676;

          /* Body */
          font-family: 'Roos St Regis Text';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          white-space: pre-line;

          ol {
            margin: 0;
          }

          &.collapse.show {
            display: flex;
            flex-direction: column;
          }
        }

        .cms-content-faq-line {
          height: 1px;
          width: calc(100% - 60px);
          background: #E4E4E4;
        }
      }
    }
  }
}
